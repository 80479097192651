<template>
  <div class="hidden">
    <Menubar
      :model="menuItems"
      :pt="{
        menu: {
          class: 'space-x-4'
        },
        submenu: {
          class: 'w-fit rounded-lg mt-4'
        }
      }"
    >
      <template #item="{ item, props: menuProps }">
        <WpButton
          v-if="item.main"
          v-ripple
          rounded="full"
          :variant="item.variant"
          class="align-items-center flex"
          button-classes="!p-2"
          v-bind="menuProps.action"
        >
          <component :is="item.icon" v-if="item.icon" />
        </WpButton>

        <template v-else>
          <a class="text-black" v-bind="menuProps.action">
            <div
              class="rounded-md bg-white p-2 shadow"
              :class="`text-${item.color}`"
            >
              <component :is="item.icon" v-if="item.icon" />
            </div>
            <div class="ml-3">
              <p class="whitespace-nowrap">{{ item.title }}</p>
              <p
                v-if="item.type.includes(parcelUrgentType)"
                class="whitespace-nowrap text-xs italic"
              >
                {{ $t('general.cancel') }}
              </p>
            </div>
          </a>
        </template>
      </template>
    </Menubar>

    <Dialog
      v-model:visible="dialog.show"
      modal
      header="Header"
      :dismissable-mask="true"
      @hide="closeDialog()"
    >
      <template #header> {{ dialog.title }} </template>
      <p v-sane-html="dialog.description" class="mb-3" />

      <WpInput
        v-model="urgentReason"
        name="Urgent reason"
        input-class="pl-10"
        :label="$t('client.reason')"
        label-class="!text-sm text-aubergine-500 mb-2 block"
        rules="required"
        :placeholder="$t('client.describeReason')"
      >
        <template #prepend>
          <PencilIcon class="absolute bottom-[9px] left-3" />
        </template>
      </WpInput>

      <template #footer>
        <div class="mt-2 grid grid-cols-2 gap-x-4">
          <WpButton variant="light-outline" @click="closeDialog()">
            {{ $t('general.cancel') }}
          </WpButton>
          <WpButton
            :variant="dialog.submitVariant"
            :disabled="!urgentReason"
            :loading="saveLoading"
            @click="handleSubmit()"
          >
            {{ dialog.submitLabel }}
          </WpButton>
        </div>
      </template>
    </Dialog>

    <Dialog
      v-model:visible="dialogCancel"
      modal
      header="Header"
      :dismissable-mask="true"
      @hide="closeDialogCancel()"
    >
      <template #header>
        {{ t('parcel.urgentDeliveryTitle') }}
      </template>
      <p
        v-sane-html="t('parcel.descriptionCancelUrgentDelivery')"
        class="mb-3"
      />

      <label class="font-bold">
        {{ $t('parcel.updateTheDeliveryDate') }}
      </label>
      <Datepicker
        v-model="deliveryDate"
        name="datepicker"
        :enable-time-picker="false"
        :clearable="false"
        :min-date="new Date()"
        :action-row="{ showNow: true, showPreview: false }"
        format="dd-MM-yyyy"
        auto-apply
        locale="nl"
        required
        :config="{ keepActionRow: true }"
        class="mt-2"
      >
        <template #action-buttons>
          <button type="button" @click="deliveryDate = new Date()">
            {{ $t('date.today') }}
          </button>
        </template>
      </Datepicker>

      <template #footer>
        <div class="mt-2 grid grid-cols-2 gap-x-4">
          <WpButton variant="light-outline" @click="closeDialogCancel()">
            {{ $t('general.cancel') }}
          </WpButton>
          <WpButton :loading="saveLoadingCancel" @click="handleSubmitCancel()">
            {{ t('general.save') }}
          </WpButton>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { addDays, isAfter } from 'date-fns'
import { deleteField, type Timestamp } from 'firebase/firestore'
const { t } = useI18n()
const { updateParcel } = useParcels()
const { user } = storeToRefs(useUserStore())
const { toast } = useHelpers()

const props = defineProps({
  parcelId: {
    type: String,
    required: true
  },
  parcelUrgentType: {
    type: String
  }
})

const menuItems = ref([
  {
    icon: 'SpeedometerIcon',
    variant: 'signal-orange',
    main: true,
    items: [
      {
        title: t('client.deliveryTomorrow'),
        type: ['nextDay'],
        icon: 'ClockOutlineIcon',
        color: 'signal-orange-500',
        command: () => menuItemClicked(['nextDay'])
      },
      {
        title: t('client.deliveryToday'),
        type: ['today', 'express'],
        icon: 'SpeedometerIcon',
        color: 'signal-orange-500',
        command: () => menuItemClicked(['today', 'express'])
      }
    ]
  }
])

const dialog = reactive({
  show: false,
  title: '',
  description: '',
  submitLabel: '',
  submitVariant: 'signal-blue'
})

function menuItemClicked(type: Array<string>) {
  if (props?.parcelUrgentType) {
    if (type.includes(props?.parcelUrgentType)) {
      urgentDeliveryCancel()
      return
    }
  }

  if (type.includes('nextDay')) {
    handleDeliveryTomorrow()
    return
  }

  handleDeliveryToday()
}

function handleDeliveryTomorrow() {
  dialog.title = t('client.deliveryTomorrow')
  dialog.description = t('client.descriptionDeliverTomorrow')
  dialog.submitLabel = t('client.deliveryTomorrow')

  urgentDeadline.value = addDays(new Date(), 1)
  urgentType.value = 'nextDay'

  dialog.show = true
}

function handleDeliveryToday() {
  dialog.title = t('client.deliveryToday')
  dialog.description = t('client.descriptionDeliverToday')
  dialog.submitLabel = t('client.deliveryToday')
  urgentType.value = 'today'

  // If it's after 10:30, show the express delivery description
  if (isAfter(new Date(), new Date().setHours(10, 30, 0, 0))) {
    dialog.description = t('client.descriptionDeliveryExpress')
    dialog.submitLabel = t('client.expressDelivery')
    dialog.submitVariant = 'signal-rose'
    urgentType.value = 'express'
  }

  dialog.show = true
}

const urgentReason = ref('')
const urgentDeadline = ref<Date | null | Timestamp>(new Date())
const urgentType = ref<'express' | 'nextDay' | 'today'>('today')

function closeDialog() {
  dialog.show = false
  dialog.submitVariant = 'signal-blue'

  urgentReason.value = ''
  urgentDeadline.value = new Date()
  urgentType.value = 'today'
}

const saveLoading = ref<boolean>(false)
async function handleSubmit() {
  saveLoading.value = true

  let deliveryDate = new Date()
  if (urgentType.value === 'nextDay') {
    deliveryDate = addDays(new Date(), 1)
  }

  await updateParcel(props.parcelId, {
    DeliveryDate: deliveryDate,
    UrgentBy: user.value.displayName!,
    UrgentDeadline: urgentDeadline.value as Timestamp,
    UrgentReason: urgentReason.value,
    UrgentType: urgentType.value
  })

  saveLoading.value = false
  toast.success(t('parcel.urgentDeliverySuccess'))

  closeDialog()
}

const dialogCancel = ref(false)
const deliveryDate = ref(new Date())

function urgentDeliveryCancel() {
  dialogCancel.value = true
}

function closeDialogCancel() {
  dialogCancel.value = false
  deliveryDate.value = new Date()
}

const saveLoadingCancel = ref(false)
async function handleSubmitCancel() {
  saveLoadingCancel.value = true

  await updateParcel(props.parcelId, {
    DeliveryDate: deliveryDate.value,
    UrgentBy: deleteField(),
    UrgentDeadline: deleteField(),
    UrgentReason: deleteField(),
    UrgentType: deleteField()
  })

  saveLoadingCancel.value = false
  toast.success(t('parcel.urgentDeliveryCanceled'))

  closeDialogCancel()
}
</script>
