<template>
  <WpCard
    ref="parcelRef"
    class="h-max"
    :class="{
      'highlight-parcel border-2 border-aubergine-500':
        highlightParcel === parcel.id
    }"
  >
    <ParcelCardHeader
      :parcel="parcel"
      :hide-action-buttons="hideActionButtons"
      :collapsable="collapsable"
      :collapse="collapsed"
      @edit="parcelEdit"
      @split="parcelSplit"
      @merge="parcelMerge"
      @collapsed="collapsed = !collapsed"
    />

    <template v-if="!collapsed">
      <template v-if="!editMode && !splitParcel && !mergeParcel">
        <ParcelContents :parcel-items="parcel?.ParcelItems" />

        <div
          v-if="!hideActionButtons"
          class="mt-4 flex items-center justify-end"
        >
          <UrgentDelivery
            :parcel-id="parcel.id"
            :parcel-urgent-type="parcel?.UrgentType"
          />
          <StatusUpdate :parcel-id="parcel.id" />
        </div>
      </template>
      <ParcelContentsEdit
        v-if="editMode"
        :parcel-id="parcel.id"
        :parcel-user-id="parcel.UserId"
        :delivery-date="parcel?.DeliveryDate"
        :parcel-items="parcel?.ParcelItems"
        @stop-edit-mode="editMode = false"
      />
      <ParcelSplit
        v-if="splitParcel"
        :parcel="parcel"
        @close="splitParcel = false"
      />
      <ParcelMerge
        v-if="mergeParcel"
        :parcel="parcel"
        :parcel-id="parcel.id"
        :parcel-items="parcel.ParcelItems"
        @close="mergeParcel = false"
      />
    </template>
  </WpCard>
</template>

<script lang="ts" setup>
import type { Parcel } from '~/models'
const { highlightParcel } = storeToRefs(useSearchParcel())

const parcelRef = ref()

const props = defineProps<{
  parcel: Parcel
  hideActionButtons?: boolean
  collapsable?: boolean
  collapse?: boolean
  completed?: boolean
}>()

const collapsed = ref(false)
onMounted(() => {
  if (props.collapsable) collapsed.value = true
})

watch(
  () => props.collapse,
  (value: boolean) => {
    collapsed.value = value
  }
)

const editMode = ref(false)
const splitParcel = ref(false)
const mergeParcel = ref(false)
const splitMergeType = ref('split')

function parcelEdit() {
  editMode.value = !editMode.value
  splitParcel.value = false
  mergeParcel.value = false

  collapsed.value = false
}

function parcelSplit() {
  splitParcel.value = !splitParcel.value
  splitMergeType.value = 'split'
  editMode.value = false
  mergeParcel.value = false

  collapsed.value = false
}

function parcelMerge() {
  mergeParcel.value = !mergeParcel.value
  splitMergeType.value = 'merge'
  editMode.value = false
  splitParcel.value = false

  collapsed.value = false
}
</script>
