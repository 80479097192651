<template>
  <div class="flex h-full">
    <WpButton
      variant="signal-blue-outline"
      class="ml-auto"
      size="md-wide"
      @click="statusModalOpen('information')"
    >
      {{ $t('parcel.parcelStatus') }}
    </WpButton>

    <WpButton
      variant="signal-orange-outline"
      class="mx-2"
      size="md-wide"
      @click="statusModalOpen('escalation')"
    >
      {{ $t('parcel.escalations') }}
    </WpButton>

    <WpButton
      variant="signal-blue-outline"
      size="md-wide"
      @click="statusModalOpen('medicationGuidance')"
    >
      {{ $t('parcel.guidance') }}
    </WpButton>

    <WpModal
      :open="modalOpen"
      :title="modalTitle"
      @close-modal="statusModalClose()"
    >
      <WpAlert class="mb-5">
        {{ $t('status.clientsGetsMessage') }}
      </WpAlert>

      <div class="grid grid-cols-2 gap-4">
        <StatusOption
          v-for="status in statuses[activeStatusType as keyof typeof statuses]"
          :key="status"
          :status="status"
          :option-selected="activeStatus"
          @click="activeStatus = status"
        />
      </div>

      <div class="mt-8 grid grid-cols-2 gap-4">
        <WpButton variant="dark-outline" @click="statusModalClose()">
          {{ $t('general.cancel') }}
        </WpButton>
        <WpButton
          variant="signal-blue"
          :disabled="!activeStatus || loading"
          :loading="loading"
          @click="updateParcelStatus(parcelId)"
        >
          {{ $t('status.sendMessage') }}
        </WpButton>
      </div>
    </WpModal>
  </div>
</template>

<script lang="ts" setup>
import { arrayUnion } from 'firebase/firestore'
import type { Parcel, ParcelStatuses } from '~/models'
const { user } = storeToRefs(useUserStore())
const { sendEventNotificare, toast } = useHelpers()
const { updateParcel } = useParcels()
const { parcelStatuses } = useParcelsData()
const { t } = useI18n()

defineProps<{
  parcelId: string
}>()

const modalOpen = ref(false)
const activeStatusType = ref('')
const activeStatus = ref('')
const loading = ref(false)

function statusModalOpen(
  type: 'information' | 'escalation' | 'medicationGuidance'
) {
  activeStatusType.value = type
  modalOpen.value = true
}

function statusModalClose() {
  activeStatusType.value = ''
  activeStatus.value = ''
  modalOpen.value = false
}

const statuses = ref<ParcelStatuses>({
  information: ['checkPackage', 'delivered', 'readyForSend', 'cancelled'],
  escalation: ['disputed'],
  medicationGuidance: ['firstIssue', 'secondIssue', 'dosChange', 'callBack']
})

const modalTitle = computed(() => {
  if (!activeStatusType.value) return ''
  return t(`status.${activeStatusType.value}`) + ' ' + t('parcel.send')
})

async function updateParcelStatus(parcelId: string) {
  loading.value = true
  const guidanceStatuses = [
    'firstIssue',
    'secondIssue',
    'dosChange',
    'callBack'
  ]

  if (!guidanceStatuses.includes(activeStatus.value)) {
    await updateParcel(parcelId, {
      CurrentStatus: activeStatus.value as Parcel['CurrentStatus'],
      CurrentStatusLabel: parcelStatuses[activeStatus.value].label,
      StatusHistory: arrayUnion({
        Actor: `${user.value.displayName}`,
        Created: new Date(),
        Status: activeStatus.value
      })
    })
      .then(() => {
        statusModalClose()
        toast.success(t('parcel.successfullyUpdated'))
      })
      .catch((error) => {
        toast.error(error)
      })
      .finally(() => {
        loading.value = false
      })
    return
  }

  sendEventNotificare({
    eventName: 'medicatie_begeleiding',
    eventData: { message: activeStatus.value }
  })
  statusModalClose()
  loading.value = false
}
</script>
